@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

@-moz-keyframes blinker {
	0% {
		opacity: 0.0;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0.0;
	}
}
@-webkit-keyframes blinker {
	0% {
		opacity: 0.0;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0.0;
	}
}
@keyframes blinker {
	0% {
		opacity: 0.0;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 0.0;
	}
}

.blink {
    -webkit-animation-name: blinker;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
    
	-moz-animation-name: blinker;
	-moz-animation-duration: 1s;
	-moz-animation-timing-function: linear;
	-moz-animation-iteration-count: infinite;
    
	animation-name: blinker;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@-moz-keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-in {
    -webkit-animation-name: fadeInOpacity;
	-webkit-animation-duration: 1s;
	-webkit-animation-timing-function: linear;
    
	-moz-animation-name: fadeInOpacity;
	-moz-animation-duration: 1s;
	-moz-animation-timing-function: linear;
    
	animation-name: fadeInOpacity;
	animation-duration: 1s;
	animation-timing-function: linear;
}

@keyframes userFadeInOut {
	0% {
		bottom: 0;
		opacity: 0;
	}
	50% {
		bottom: 15px;
		opacity: 1;
	}
	100% {
		bottom: 15px;
		opacity: 0;
	}
}
